/* eslint-disable jsx-a11y/img-redundant-alt */
import east from './../../../../static/icons/east.svg';
import reads from './type/reads.svg';
import ViewItem from '../../../../hooks/ViewItem';
import { useEffect, useRef } from 'react';
import { onMobile } from '../../../../modules/client';

export default function Feed() {
  const feed = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('section') === 'reads') {
      feed.current?.scrollIntoView();
      window.scrollBy(0, -80);
    }
  }, []);

  return (
    <div className='feed' ref={feed}>
      <div className='feed-container'>
        <ViewItem>
          <a href='/hello'>
            <div className={onMobile() ? 'feed-item' : 'feed-item feed-item-hover'}>
              <h3>Article</h3>
              <h4>Hello</h4>
              <p>Introduction to me and my professional self.</p>
              <div>
                <p>Read</p>
                <img src={east} alt='Arrow pointing east' />
              </div>
            </div>
          </a>
        </ViewItem>
        <div className={'feed-image'}>
          <img src={reads} alt='Reads' />
        </div>
        <a href='/transit'>
          <ViewItem>
            <div className={onMobile() ? 'feed-item' : 'feed-item feed-item-hover'}>
              <h3>Article</h3>
              <h4>Transit</h4>
              <p>My approach towards working with APIs.</p>
              <div>
                <p>Read</p>
                <img src={east} alt='Arrow pointing east' />
              </div>
            </div>
          </ViewItem>
        </a>
        <a href='/juncture'>
          <ViewItem>
            <div className={onMobile() ? 'feed-item' : 'feed-item feed-item-hover'}>
              <h3>Article</h3>
              <h4>Juncture</h4>
              <p>Few thoughts on building interfaces.</p>
              <div>
                <p>Read</p>
                <img src={east} alt='Arrow pointing east' />
              </div>
            </div>
          </ViewItem>
        </a>
        {/* <a href='/features'> */}
        <ViewItem>
          <div className={'feed-item inactive'}>
            <h3>Article</h3>
            <h4>Crispies</h4>
            <p>Web media quality and performance optimization.</p>
            <div>
              {/* <p>Read</p> */}
              {/* <img src={east} alt='Arrow pointing east' /> */}
            </div>
          </div>
        </ViewItem>
        {/* </a> */}
      </div>
    </div>
  );
}
