import { useEffect, useRef, useState } from 'react';
import logo from './static/logo.svg';
import menu from './../../static/icons/menu.svg';
import close from './../../static/icons/close.svg';

export default function Navigation() {
  const [menuExpanded, setMenuExpanded] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  const links = useRef<HTMLElement[]>([]);
  const home = useRef<HTMLImageElement>(null);
  const expand = useRef<HTMLImageElement>(null);

  const toggleMenuExpanded = () => setMenuExpanded((prevCheck) => !prevCheck);

  useEffect(() => {
    const pinElements = (container: HTMLDivElement, images: HTMLImageElement[], links: HTMLElement[]) => {
      container.style.backgroundColor = '#131313';
      images.forEach((image: HTMLElement) => {
        image.style.transform = 'unset';
        image.style.width = '36px';
      });
      links.forEach((link: HTMLElement) => {
        link.style.fontSize = '18px';
        link.style.transform = 'unset';
      });
    };

    const unpinElements = (navigationContainer: HTMLDivElement, images: HTMLImageElement[], links: HTMLElement[]) => {
      navigationContainer.style.backgroundColor = 'transparent';
      images.forEach((image: HTMLElement) => {
        image.style.transform = 'translateY(60px)';
        image.style.width = '48px';
      });
      links.forEach((link: HTMLElement) => {
        link.style.fontSize = '28px';
        link.style.transform = 'translateY(60px)';
      });
    };

    const handleScroll = () => {
      if (!container.current || !home.current || !expand.current) {
        return;
      }

      window.scrollY > 0
        ? pinElements(container.current, [home.current, expand.current], links.current)
        : unpinElements(container.current, [home.current, expand.current], links.current);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='navigation-container' ref={container}>
      <div className='navigation'>
        <div>
          <img src={logo} alt='Logo reading "JL"' onClick={() => (window.location.href = '/')} ref={home} />
        </div>
        <div className='entries'>
          <div>
            <a href='/projects' ref={(link) => link && !links.current?.includes(link) && links.current?.push(link)}>
              Projects
            </a>
          </div>
          <div>
            <a href='/contact' ref={(link) => link && !links.current?.includes(link) && links.current?.push(link)}>
              Contact
            </a>
          </div>
        </div>
        <div className='menu'>
          <img src={menu} ref={expand} alt={'Open menu'} onClick={() => toggleMenuExpanded()} />
          {menuExpanded && (
            <div>
              <div>
                <a href='/'>Home</a>
                <a href='/projects'>Projects</a>
                <a href='/contact'>Contact</a>
                <span onClick={() => toggleMenuExpanded()}>
                  <img src={close} alt={'Close menu'} />
                  <p>Close</p>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
