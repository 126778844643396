import silhouette from './static/moose.jpg';
import west from './../../../../../static/icons/west.svg';

export default function Features() {
  return (
    <>
      <div className='article-container'>
        <div>
          <h1>Crisipies</h1>
          <h2>Features beyond what's expected</h2>
          <p className='deck'>
            I aim to always deliver unique and innovative <i>key features</i> on par with all the amazing tools available to build them, and to go
            beyond what's expected. What the features should be I think depends on the project, while the purpose always is to take the project to the
            next level.
          </p>
          <img src={silhouette} alt='Silhouette' />
          <p className='body'>Something...</p>
          <button className='back-button' onClick={() => (window.location.href = '/?section=reads')}>
            <img src={west} alt='Arrow pointing east' />
            <p>Back to reads feed</p>
          </button>
        </div>
      </div>
    </>
  );
}
