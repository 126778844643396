import { useEffect, useRef, useState } from 'react';
import down from './static/down.svg';
import { onMobile } from '../../../../modules/client';

export default function Banner() {
  const [scrollY, setScrollY] = useState(0);
  const banner = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY / 2);
    window.addEventListener('scroll', handleScroll);

    if (onMobile() && banner.current) {
      banner.current.style.minHeight = `${banner.current.offsetHeight + 1}px`;
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className='banner' ref={banner}>
      <h1 style={{ transform: `scaleY(1.25) rotateY(10deg) translateX(calc(-50% + 12px)) translateY(${-scrollY * 0.5}px)` }}>JONAS LIND</h1>
      <h2 style={{ transform: `rotateY(10deg) translateX(calc(50% + 18px)) translateY(${-scrollY * 0.5 + 58}px)` }}>FULL-STACK DEVELOPER</h2>
      <img src={down} alt='Arrow pointing downward' />
      <div />
    </div>
  );
}
