import silhouette from './static/silhouette.jpg';
import west from './../../../../../static/icons/west.svg';

export default function Hello() {
  return (
    <>
      <div className='article-container'>
        <div>
          <h1>'Ello,</h1>
          <h2>pleased to meet you</h2>
          <p className='deck'>I'm Jonas. I code and produce all kinds media to craft applications of all sorts.</p>
          <img src={silhouette} alt='Silhouette' />
          <p className='body'>
            I got into technology in the late 90s, when a friend with older brothers introduced me to coding and computer tinkering in general, as
            well as the internet culture of the time, which consisted mostly of playing MP3s and hanging out on IRC.
          </p>
          <p>
            I went on to study media at high school and have since completed post-secondary studies in graphics production, as well as in software
            engineering. I like keeping up with trends while also exploring ways to push things forwards.
          </p>
          <button className='back-button' onClick={() => (window.location.href = '/?section=reads')}>
            <img src={west} alt='Arrow pointing east' />
            <p>Back to reads feed</p>
          </button>
        </div>
      </div>
    </>
  );
}
