/* eslint-disable jsx-a11y/img-redundant-alt */
import stack from './type/stack.svg';
import turn from '../../../../static/icons/turn.svg';
import ViewItem from '../../../../hooks/ViewItem';
import { useRef } from 'react';
import { onMobile } from '../../../../modules/client';

export default function Service() {
  const services = useRef<HTMLElement[]>([]);

  function flip(index: number) {
    if (!services.current[index].classList.contains('flipped-service-item')) {
      services.current[index].classList.add('flipped-service-item');
    } else {
      services.current[index].classList.remove('flipped-service-item');
    }
  }
  return (
    <div className='service'>
      <div className='service-container'>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(0)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Frontend</h4>
                <p>Interface development using modern techniques and frameworks.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>Streamlined and well considered methodology using frameworks like React.js, TypeScript and Sass.</p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
        <div className='service-image'>
          <img src={stack} alt='Stack' />
        </div>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(1)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Backend</h4>
                <p>Sustainable and secure user platform and utility development.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>Java, C# and PHP with focus on maintainability and best practice in regards to configuratin and design.</p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(2)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Cloud</h4>
                <p>Database and server configuration, maintenance and optimization.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>
                  Development utilizing techniques and cloud services like Apache HTTP Server, Microsoft Azure, SQL Server, MongoDb, MySQL and SQLite.
                </p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(3)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Media</h4>
                <p>Standardized type, image and video media production.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>Technical orientation toward producing graphic content using vectors and bitmaps.</p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(4)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Design</h4>
                <p>Web interface and graphic profile development.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>Prototyping and preliminary studies with a practical approach focusing on implementation and results.</p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
        <ViewItem>
          <div className={onMobile() ? 'flipping-service-item' : 'flipping-service-item flipping-service-item-hover'} onClick={() => flip(5)}>
            <div
              className='flipping-service-item-inner'
              ref={(service) => service && !services.current?.includes(service) && services.current?.push(service)}
            >
              <div className='flipping-service-item-front'>
                <h4>Requisite</h4>
                <p>Conceptualization and requisites analysis.</p>
                <img src={turn} alt='Read more' />
              </div>
              <div className='flipping-service-item-back'>
                <p>Organizing and concretization with emphasis on feasibility, performance and delivery.</p>
                <img
                  src={turn}
                  alt='Read more'
                  style={{
                    transform: 'scaleX(-1)',
                    filter: 'brightness(1.85)',
                  }}
                />
              </div>
            </div>
          </div>
        </ViewItem>
      </div>
    </div>
  );
}
